import React, { useState } from 'react';

import './App.css';

function Status(props) {

  const [show, setShow] = useState(false);

  let border_style = {border: "2px solid blue"}
  let title_style = {color: "white"}

  if (props.status.status === "Operational") {
    border_style = {border: "1px solid white"}
    title_style = {color: "lightgreen"}
  } else if (props.status.status === "Degraded performance") {
    border_style = {border: "1px solid yellow"}
    title_style = {color: "yellow"}
  } else if (props.status.status === "Down") {
    border_style = {border: "1px solid red"}
    title_style = {color: "white"}
  } 

  return (
    <div className="status-container" style={border_style} onClick={() => {setShow(!show)}}>
      <div className="status-line">
        <h2>{props.service.name} - <span style={title_style}>{props.status.status}</span></h2>
      </div>
      {
        show ?
          <>
            <div className="data-line">
              <p>Last response time: {props.status.time} ms</p>
            </div>
            <div className="data-line">
              <p>Last code: {props.status.code}</p>
            </div>
          </>
          :
          <></>
      }
      
      
    </div>
  );
}

export default Status;
