import './App.css';
import Status from './Status';
import ReloadBar from './ReloadBar';

import React from 'react';

const services = [
  {
    name: "Web",
    id: "web",
    url: "https://www.rinconingenieril.es/data.php",
    degraded_time: 140
  },
  {
    name: "API REST",
    id: "api",
    url: "https://api.rinconingenieril.es/",
    degraded_time: 140
  },
  {
    name: "CDN (De)",
    id: "cdn",
    url: "https://cdn.rinconingenieril.es/load.php",
    degraded_time: 140
  },
  {
    name: "Contacto",
    id: "contacto",
    url: "https://contacto.rinconingenieril.es/",
    degraded_time: 110
  },
  {
    name: "Go",
    id: "go",
    url: "https://go.rinconingenieril.es/",
    degraded_time: 140
  },
  {
    name: "Experiments",
    id: "experiments",
    url: "https://experiments.rinconingenieril.es/",
    degraded_time: 110
  },
  {
    name: "Ninja",
    id: "ninja",
    url: "https://obsninja.rinconingenieril.es/",
    degraded_time: 110
  }
]

class App extends React.Component {

  time = {}

  constructor(props) {
    super(props);
    this.state = {
      services: {},
      time_to_reload: 30,
    };
  }

  componentWillMount() {
    services.forEach((service) => {
      let state = this.state.services
      state[service.id] = {status: "waiting", code: "n/a", time: "n/a"}
      this.setState({services: state})
    });
  }

  componentDidMount() {
    this.check();
    this.check();
    setInterval(() => {
      let time = this.state.time_to_reload > 0 ? this.state.time_to_reload - 1 : 30
      console.log(time)
      this.setState({time_to_reload: time})
      if (time === 30) this.check();
    }, 1000);  
      
  }

  check = () => {
    services.forEach((service) => {
      this.time[service.id] = new Date().getTime();
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function() { 
        let state = this.state.services
        let time = new Date().getTime() - this.time[service.id]
        let status = "Down"

        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          status = time < service.degraded_time ? "Operational" : "Degraded performance"
        }
        state[service.id] = {status: status, code: xmlHttp.status, time: time}
        this.setState({services: state})
      }.bind(this);
      xmlHttp.open("GET", service.url, true); // true for asynchronous 
      xmlHttp.send(null);
    })


  }

  render() {
    return (
      <div className="App">
        <ReloadBar time={this.state.time_to_reload}/>
        <header className="App-header">
          <h1>Status</h1>
        </header>
        <div className="status-page">
          {
            services.map(service => <Status service={service} status={this.state.services[service.id]}/>)
          }
        </div>
        <p>Status is under development.</p>
      </div>
    );
  }
}

export default App;
