import React from 'react';
import ReactTooltip from 'react-tooltip';

import './App.css';

const MAX_TIME = 30;

function ReloadBar(props) {

  let old_width = (props.time * 100) / MAX_TIME
  let new_width = ((props.time - 1) * 100) / MAX_TIME

  return (
    <div className="reload-bar-container">
      <div className="reload-bar" style={{maxWidth: old_width.toString() + "vw", minWidt: new_width.toString() + "vw"}} data-tip={"Proxima actualización en " + props.time}>
        
      </div>
      <ReactTooltip place="top" type="dark" effect="float"/>
    </div>
  );
}

export default ReloadBar;
